import React from 'react'
import {Time} from "./time";
import {FullForm} from "./form/full.form";
import {SubmitModal} from "./submit.modal";
import {ValidationErrorModal} from "./validation.error.modal";
import {observer} from "mobx-react";

@observer
class Page extends React.Component {
    render() {
        return (
            <div className={"container"}>
                <div className={"row"}>
                    <div className={"col-md-2"}></div>
                    <div className={"col-md-8"}>
                        <div className={"row"}>
                            <div className={"col-md-2"}>
                                <div className={"kv-logo"}/>
                            </div>
                        </div>
                        <FullForm/>
                    </div>
                </div>
                <SubmitModal/>
                <ValidationErrorModal/>
            </div>
        )
    }
}

export {Page}