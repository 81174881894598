import {observable, action, computed} from "mobx";
import {UploadStore} from "./upload.store";
import {isBlankString, isEmailValid, isEnglishOnly, isPhoneNumberValid, isAlphaNumeric} from "../utils/validator";
import axios from 'axios';
import {makeid, convertPhoneNumber, convertDate} from "../utils/common";

const DEFAULT_CONFIG = { headers: { "Content-Type": "application/json" } };
const MULTIPART_CONFIG = { headers: { "Content-Type": "multipart/form-data" } };
const URLENCODED_CONFIG = { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } }


const FieldTypes = {
    "Text" : "Text",
    "Email" : "Email",
    "Number" : "Number",
    "Date" : "Date"
};

class Field {
    @observable value = "";
    @observable isValid = true;
    @observable name = "";
    @observable title = "";
    @observable type = "";
    @observable required = false;


    constructor(type = FieldTypes.Text, title = "", required = false) {
        this.type = type;
        this.title = title;
        this.required = required;

        this.id = makeid(8);
    }

    setValue (value) {
        this.value = value;
        this.isValid = true;
    }
}

class TextField extends Field {
    constructor(title, required) {
        super(FieldTypes.Text, title, required);
    }
}

class EmailField extends Field {
    constructor(title, required) {
        super(FieldTypes.Email, title, required);
    }
}

class DateField extends Field {
    constructor(title, required) {
        super(FieldTypes.Date, title, required);
    }
}

const validateForEmptyString = (fields = []) => {
    fields.forEach((f) => {
        if (isBlankString(f.value)) {
            f.isValid = false;
        }
    });
}

const validateForEnglish = (fields = []) => {
    fields.forEach((f) => {
        if (!isEnglishOnly(f.value)) {
            f.isValid = false;
        }
    });
}

class Person {
    @observable lastName = new TextField("שם משפחה (אנגלית בלבד)", true);
    @observable middleName = new TextField("שם אמצעי (אנגלית בלבד)", false);
    @observable firstName =  new TextField("שם פרטי (אנגלית בלבד)", true);
    @observable title = "";


    @observable age = "Adult";
    @observable gender = "Male";

    @observable passportNumber =  new TextField("מספר דרכון", true);
    @observable passportIssueDate = new DateField("תאריך הנפקת הדרכון", true);
    @observable passportExpiration = new DateField("תוקף הדרכון", true);
    @observable passportNationality = new TextField("מקום הוצאת הדרכון (אנגלית בלבד)", true);

    @observable birthDate = new DateField("תאריך לידה", true);
    @observable countryOfBirth = new TextField("ארץ לידה (אנגלית בלבד)", true);
    @observable mobilePhone = new TextField("טלפון נייד", true);
    @observable email = new EmailField("דואר אלקטרוני", true);

    @observable hotelName = new TextField("שם המלון במדינת היעד (אנגלית בלבד)", true);
    @observable flightNumber1 = new TextField("מספר טיסת ההלוך", true)
    @observable flightDate1 = new DateField("תאריך טיסת ההלוך", true);

    @observable flightNumber2 = new TextField("מספר טיסת החזור", true);
    @observable flightDate2 = new DateField("תאריך טיסת החזור", true);

    @observable passportImage = new UploadStore("צילום דרכון", "אנא העלו צילום דרכון עדכני, ברור ככל האפשר");
    @observable passengerPhoto = new UploadStore("תמונה של הנוסע", "אנא העלו תמונה של הנוסע עדכני, ברור ככל האפשר");

    constructor(parent) {
        this._parent = parent;

        this.allFields = [];
        this.allFields.push(this.lastName);
        this.allFields.push(this.middleName);
        this.allFields.push(this.firstName);
        this.allFields.push(this.passportNumber);
        this.allFields.push(this.passportIssueDate);
        this.allFields.push(this.passportExpiration);
        this.allFields.push(this.passportNationality);
        this.allFields.push(this.birthDate);
        this.allFields.push(this.countryOfBirth);
        this.allFields.push(this.mobilePhone);
        this.allFields.push(this.email);
      //  this.allFields.push(this.hotelName);
        this.allFields.push(this.flightNumber1);
        this.allFields.push(this.flightDate1);
        this.allFields.push(this.flightNumber2);
        this.allFields.push(this.flightDate2);

    //    this.allFields.push(this.passportImage);
    //    this.allFields.push(this.passengerPhoto);
    }

    @computed get isFormValid() {
        let isValid = true;

        this.allFields.map((f) => {
            if (!f.isValid) {
                isValid = false;
            }
        });

        return isValid;
    }

    @computed get jsonData() {
        const d = {
            "lastName" : this.lastName.value,
            "middleName" : this.middleName.value,
            "firstName" : this.firstName.value,
            "age" : this.age,
            "gender" : this.gender,
            "passportNumber" : this.passportNumber.value,
            "passportIssueDate" : convertDate(this.passportIssueDate.value),
            "passportExpiration" : convertDate(this.passportExpiration.value),
            "passportNationality" : this.passportNationality.value,
            "birthDate" : convertDate(this.birthDate.value),
            "countryOfBirth" : this.countryOfBirth.value,
            "mobilePhone" : convertPhoneNumber(this.mobilePhone.value),
            "email" : this.email.value,
            "hotelName" : this.hotelName.value,
            "flightNumber1" : this.flightNumber1.value,
            "flightDate1" : convertDate(this.flightDate1.value),
            "flightNumber2" : this.flightNumber2.value,
            "flightDate2" : convertDate(this.flightDate2.value),
            "passportImage" : this.passportImage.serverFile,
            "passengerPhoto" : this.passengerPhoto.serverFile
        }

        return d;
    }

    @action copyData() {
        console.log("copy data", this);
        if (this.email.value === "") {
            this.email.setValue(this._parent.email.value);
        }

        if (this.mobilePhone.value === "") {
            this.mobilePhone.setValue(this._parent.mobilePhone.value);
        }
    }

    @action setGender(newValue) {
        console.log("setGender", newValue);
        this.gender = newValue;
    }

    @action setAge (newValue) {
        console.log("setAge", newValue);
        this.age = newValue;
    }

    @action validateForm() {
        console.log("person validate");

        validateForEnglish([
            this.lastName,
            this.firstName,
            this.countryOfBirth,
         //   this.hotelName,
            this.passportNationality
        ])

        validateForEmptyString([
            this.passportNumber,
            this.passportIssueDate,
            this.passportExpiration,
            this.birthDate,
            this.flightNumber1,
            this.flightDate1,
            this.flightNumber2,
            this.flightDate2
        ]);

        if (!isEmailValid(this.email.value)) {
            this.email.isValid = false;
        }

        if (!isPhoneNumberValid(this.mobilePhone.value)) {
            this.mobilePhone.isValid = false;
        }

    //    this.passportImage.validateForm();
    //   this.passengerPhoto.validateForm();
    }
}

class FormStore {
    @observable orderNumber = new TextField("מספר הזמנה", true);
    @observable email = new EmailField("דואר אלקטרוני", true);
    @observable mobilePhone = new TextField("טלפון נייד", true);
    @observable agencyName = new TextField("שם הסוכנות");

    @observable orderPlace = "קווי חופשה";

    @observable passengerCount = 1;

    @observable passengersArr = [1,2,3,4,5,6];

    @observable passengers = [];

    @observable isSubmitted = false;
    @observable destinationsData = null;
    @observable selectedDestination = null;


    // Dubai
    @observable needDubaiVisa = null;
    @observable dubaiVisaType = "";

    constructor(root) {
        this._root = root;
        this.passengers.push(new Person(this));

        // load destinations
        axios.get("https://pass.kavei.co.il/api/destinations",DEFAULT_CONFIG)
            .then((res) => {
                console.log("res",res);
                this.destinationsData = res.data;

            })
            .catch((e) =>{
                console.error(e);
            })
    }

    @computed get destinationsArray() {
        if (!this.destinationsData) {
            return [];
        }

        const arr = this.destinationsData.combosDestinations || [];
        const all = [];

        arr.map((country) => {

            country.cities.map((city) => {
                all.push(city);
            })
        });


        all.sort((a,b) => {
            if (a.n > b.n) {
                return 1;
            } else if (a.n < b.n) {
                return -1;
            }

            return 0;
        })

        console.log("all destinations",all);
        return all;
    }

    @computed get selectedDestinationName() {
        if (!this.selectedDestination) {
            return "";
        }

        return this.selectedDestination.n;
    }

    @computed get isFormValid() {
        let isValid = true;

        const allFields = [
            this.orderNumber,
            this.email,
            this.mobilePhone,
            this.agencyName
        ];

        allFields.map((f) => {
            if (!f.isValid) {
                isValid = false;
            }
        });

        if (!this.selectedDestination) {
            console.warn('destination not seleted');
            isValid = false;
        }

        if (this.selectedDestination && this.selectedDestination.c === 'DXB') {
            if (this.needDubaiVisa === null) {
                console.warn('visa not selected');

                isValid = false;
            }

            if (this.needDubaiVisa && !this.dubaiVisaType) {
                console.warn('visa type not selected');

                isValid = false;
            }
        }

        this.passengers.forEach((p) => {
            if (!p.isFormValid) {
                isValid = false;
            }
        })

        return isValid;
    }

    @action postForm() {
        const d = this.jsonData;



        console.log("jsonData", d);

        axios.post("https://pass.kavei.co.il/api/form", d,DEFAULT_CONFIG)
            .then((res) => {
                console.log("res",res);
                this._root.submitModal.openModal();
                this.isSubmitted = true;
            })
            .catch((e) =>{
                console.error(e);
            })
    }

    @computed get jsonData() {
        const needDubaiVisa = (this.selectedDestination && this.selectedDestination.c === 'DXB') ? this.needDubaiVisa : false;
        const dubaiVisaType = (this.selectedDestination && this.selectedDestination.c === 'DXB') ? this.dubaiVisaType : "";

        const d = {
            "destination" : this.selectedDestination,
            "needDubaiVisa" : needDubaiVisa,
            "dubaiVisaType" : dubaiVisaType,
            "orderNumber" : this.orderNumber.value,
            "email" : this.email.value,
            "mobilePhone" : convertPhoneNumber(this.mobilePhone.value),
            "orderPlace" : this.orderPlace,
            "agencyName" : this.agencyName.value,
            "passengerCount" : this.passengerCount,
            "passengers" : this.passengers.map((p) => {
                return p.jsonData
            })
        }

        console.log("fullJSON", d);

        return d;
    }

    @observable setOrderPlace(newValue) {
        this.orderPlace = newValue;

        if (newValue === "קווי חופשה") {
            this.agencyName.required = false;
        } else {
            this.agencyName.required = true;
        }
    }

    @action updateArray() {
        console.log("updateArray");

        let diff = this.passengerCount - this.passengers.length;
        console.log("diff",diff);
        if (diff > 0) {
            for (let i=0;i<diff;i++) {
                this.passengers.push(new Person(this));
            }
        }

        if (diff < 0) {
           diff = Math.abs(diff);
           this.passengers.splice(this.passengers.length-diff,diff);
        }

        this.passengers.forEach((p) =>{
            p.copyData();
        })
    }

    @action validateForm() {
        console.log("validateForm");

        if (!isEmailValid(this.email.value)) {
            this.email.isValid = false;
        }

        if (isBlankString(this.orderNumber.value) || !isAlphaNumeric(this.orderNumber.value)) {
            this.orderNumber.isValid = false;
        }

        if (!isPhoneNumberValid(this.mobilePhone.value)) {
            this.mobilePhone.isValid = false;
        }

        if (this.agencyName.required) {
            if (isBlankString(this.agencyName.value)) {
                this.agencyName.isValid = false;
            }
        } else {
            this.agencyName.isValid = true;
        }

        this.passengers.map((p) => {
            p.validateForm();
        });
    }


}

export {FormStore,FieldTypes}