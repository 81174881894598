const validAreaCodes = ["02","03","04","08", "09", "071", "072", "073", "074", "076", "077", "078", "079", "050", "051",
    "052", "053", "054", "055", "056", "058", "059"];

const isEnglishOnly =  (str) => {
    var r = /^[\sa-zA-Z]+$/;
    return r.test(str);
}

const isEmailValid = (emailStr) => {
    var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return re.test(emailStr);
}

const isBlankString = (str) => {
    return (!str || /^\s*$/.test(str));
}

const isPhoneNumberValid = (phoneStr) => {
    var pattern = /^\d{2,3}\-?\d{7}$/;

    var r = pattern.test(phoneStr);
    return r;
}

const isPhoneAreaCodeValid = (areaCode) => {

    return validAreaCodes.find((code) => {
        return (code === areaCode)
    });
}

const isPhoneNumberWithoutAreaValid = (phoneNumber) => {

    var phoneRegEx = new RegExp("^\\d{7}$");

    if ((phoneRegEx.exec(phoneNumber) != null)) {
        return true;
    }

    return false;
}

const isAlphaNumeric = (inputTxt) => {
    const letterNumber = /^[0-9a-zA-Z]+$/;
    return (inputTxt.match(letterNumber));
}


export {
    isEnglishOnly,
    isEmailValid,
    isBlankString,
    isPhoneNumberValid,
    isPhoneAreaCodeValid,
    isPhoneNumberWithoutAreaValid,
    isAlphaNumeric
}