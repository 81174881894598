import {observable, computed, action} from "mobx";



class UploadStore {
    @observable selectedFile = "";
    @observable title = "";
    @observable description = "";
    @observable isUploading = false;
    @observable progress = 0;
    @observable serverFile = "";
    @observable isValid = true;

    constructor(title, description) {
        this.title = title;
        this.description = description;
    }

    @action validateForm() {
        if (this.serverFile !== "") {
            this.isValid = true;
        } else {
            this.isValid = false;
        }
    }

    @action cancelUpload() {
        if (this.ajax) {
            this.ajax.abort();
        }

        this.selectedFile = "";
        this.progress = 0;
        this.isUploading = false;
    }

    @action uploadFile(files) {
        console.log("upload file", file);

        var file = files ? files[0] : null;//_("file1").files[0];
        if (!file) {
            console.log("no file", files);
            return;
        }

        this.selectedFile = file.name;

        // prepare handlers
        const completeHandler = (event) => {
            console.log("completeHandler",event.target.responseText );
            const d = JSON.parse(event.target.responseText);
            this.serverFile = d.filename;
            this.isValid = true;
            console.log("d.filename",d.filename);
        }

        const progressHandler = (event) => {

            const percent = (event.loaded / event.total) * 100;

            this.progress = percent;
            console.log("progressHandler", event.loaded, event.total,percent);

        }

        const errorHandler = (event) => {
            console.log("error",event);


            this.selectedFile = "";
            this.progress = 0;
            this.isUploading = false;

            alert("upload error");
        }

        const abortHandler = (event) => {
            console.log("abortHandler",abortHandler);
        }


        // alert(file.name+" | "+file.size+" | "+file.type);
        var formdata = new FormData();
        formdata.append("file1", file);
        this.ajax = new XMLHttpRequest();
        this.ajax.upload.addEventListener("progress", progressHandler, false);
        this.ajax.addEventListener("load", completeHandler, false);
        this.ajax.addEventListener("error", errorHandler, false);
        this.ajax.addEventListener("abort", abortHandler, false);
        //this.ajax.open("POST", "http://localhost:4000/api/upload");
        this.ajax.open("POST", "https://pass.kavei.co.il/api/upload");
        //use file_upload_parser.php from above url
        this.ajax.send(formdata);
    }



}

export {UploadStore}