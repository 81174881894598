import {TimeStore} from "./time.store";
import {FormStore} from "./form.store";
import {ModalStore} from "./modal.store";


const allStores = {
    timeStore : new TimeStore(),
    submitModal : new ModalStore(),
    validationModal : new ModalStore()
};

allStores.formStore = new FormStore(allStores)


export default allStores;