import React from 'react'
import {inject, observer} from "mobx-react";
import {Dropdown, Button, Form, Modal} from "react-bootstrap";
import {FormField} from "./field";
import {PersonForm} from './person.form';
import {DubaiSection} from "./dubai.section";

@inject ("formStore","validationModal") @observer
class FullForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            validated : false
        }
    }
    render() {
        const s = this.props.formStore;

        const handleSubmit = (event) => {
            const form = event.currentTarget;
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            }

            this.setState({
                validated : true
            });

            s.validateForm();

            event.preventDefault();
            event.stopPropagation();
            //setValidated(true);


            if (!s.isFormValid) {
                this.props.validationModal.openModal();
                return;
            }

            s.postForm();
        };
        const checkedAgency = (s.orderPlace === "סוכנות נסיעות");
        const checkedKavei = (s.orderPlace === "קווי חופשה");
        let agencyDiv = "";
        if (checkedAgency) {
            agencyDiv = <FormField field={s.agencyName}/>
        }

        if (s.isSubmitted) {
            return (
                <div className="alert alert-success" role="alert">
                    הטופס נשלח בהצלחה, חברתנו מאחלת לכם טיסה נעימה וחופשה מהנה.
                </div>
            )
        }

        return (
            <div>
                <Form noValidate onSubmit={handleSubmit}>
        <div className={"row"}>
            <div className={"col-md-12"}>
                <h1>השלמת פרטי נוסע</h1>
                <p>לקוחות יקרים, אנו שמחים לארח אתכם בטיסתכם הקרובה ומאחלים
                    לכם טיסה נעימה וחופשה מהנה.
                </p>

                <p>בהתאם לדרישת חברות התעופה, הנכם נדרשים למלא את הפרטים הבאים:</p>
            </div>

            <div className={"col-md-12"}>
                <div>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <FormField field={s.orderNumber} maxLength={12}/>
                        </div>

                        <div className="form-group col-md-6">
                            <FormField field={s.email} maxLength={50}/>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className="form-group col-md-6">
                            <FormField field={s.mobilePhone} maxLength={15}/>
                        </div>

                        <div className="form-group col-md-6">
                            <label className={"ui-label"}>מספר הנוסעים</label>
                            <Dropdown>
                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                    {s.passengerCount}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {s.passengersArr.map((item) => {
                                        return <Dropdown.Item key={"passenger" + item} onClick={() => {
                                            s.passengerCount = item;
                                            s.updateArray();
                                        }}>{item}</Dropdown.Item>
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"form-group col-md-6"}>
                            <label className={"ui-label"}>הזמנתי מ-</label>
                            <div className={"row"}>
                                <div className={"col-md-6"}>
                                    <Form.Check name={"orderFrom"} id={"order_from_agency"} type={"radio"} label={"סוכנות נסיעות"} checked={checkedAgency} onChange={() => {
                                        s.setOrderPlace("סוכנות נסיעות");
                                    }}/>
                                </div>
                                <div className={"col-md-6"}>
                                    <Form.Check name={"orderFrom"} id={"order_from_kavei"} type={"radio"} label={"קווי חופשה"} checked={checkedKavei} onChange={() => {
                                        s.setOrderPlace("קווי חופשה");
                                    }}/>
                                </div>
                            </div>
                        </div>

                        <div className="form-group col-md-6">
                            {agencyDiv}
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"form-group col-md-6"}>
                            <label className={"ui-label"}>טסים ל-</label>
                            <Dropdown>
                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                    {s.selectedDestinationName || "בחר יעד"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {s.destinationsArray.map((item) => {
                                        return <Dropdown.Item key={"dest" + item.c} onClick={() => {
                                            s.selectedDestination = item;
                                        }}>{item.n}</Dropdown.Item>
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>


                    </div>

                        <DubaiSection/>

                </div>


            </div>
        </div>
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <br/><br/>
                </div>
                {s.passengers.map((item,index)=>{
                    return <PersonForm key={"person"+index} person={item} number={index+1} />
                })}
            </div>

                <div className={"row"}>

                    <div className={"col-md-10"}>
                        <br/><br/>
                    <p>מובהר כי קווי חופשה מהווה גורם מקשר בלבד ואינה אחראית לשימוש
                        שיעשה במידע או לאמצעי אבטחת המידע הננקטים על ידי חברת התעופה או
                        הרשות אליה יועבר המידע. מילוי הפרטים ושליחות מהווה הסכמה להעברת
                        המידע אודות הנוסעים (לרבות קטינים ככל שכלולים בהזמנה).</p>
                    </div>

                    <div className={"col-md-2"}>
                        <br/><br/>
                        <Button type={"submit"} variant="primary">שלח</Button>

                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-md-12"}><br/></div>
                </div>
                </Form>
        </div>);

    }
}

export {FullForm}