import React from 'react'
import {observer, inject} from "mobx-react";
import {Form} from "react-bootstrap";


@inject ("formStore") @observer
class NoNeedVisa extends React.Component {
    render() {
        const s = this.props.formStore;

        if (this.props.formStore.needDubaiVisa === false) {
            return <div>מעולה! אם כך, אנא המשיכו למלא את הפרטים הנדרשים עבור חברת התעופה.</div>;
        } else {
            return null;
        }
    }
}


@inject ("formStore") @observer
class VisaTypeSection extends React.Component {
    render() {
        const s = this.props.formStore;

        if (!this.props.formStore.needDubaiVisa) {
            return null;
        }

        const checkedRegularVisa = (s.dubaiVisaType === "regular");
        const checkedFastVisa = (s.dubaiVisaType === "fast");

        return (
            <div>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <p>אנא בחרו את האשרה הרצויה:</p>
                    </div>
                    <div className={"form-group col-md-12"}>
                        <Form.Check name={"visaType"} id={"order_regular_visa"} type={"radio"} label={"אשרה רגילה לכניסה חד פעמית עד ל-30 יום (ניתן להגיש את הבקשה עד 72 שעות לפני מועד ההמראה) בעלות של כ- 95$ לנוסע."} checked={checkedRegularVisa} onChange={() => {
                            //s.setOrderPlace("סוכנות נסיעות");
                            s.dubaiVisaType = "regular";
                        }}/>
                    </div>
                    <div className={"form-group col-md-12"}>
                        <Form.Check name={"visaType"} id={"order_fast_visa"} type={"radio"} label={"     אשרה מהירה לכניסה חד פעמית עד ל-30 יום (ניתן להגיש את הבקשה עד 24 שעות לפני מועד ההמראה) בעלות של כ-105$ לנוסע."} checked={checkedFastVisa} onChange={() => {
                            //  s.setOrderPlace("קווי חופשה");
                            s.dubaiVisaType = "fast";
                        }}/>
                    </div>
                </div>


                <div className={"row"}>
                    <div className={"col-md-12"}>

                        <p>כמה פרטים שחשוב לדעת !</p>
                        <ul>
                            <li>קווי חופשה אינה נושאת באחריות על הליך הנפקת אשרת הכניסה ובכלל זה סירוב לקבלת אשרת כניסה וכן אינה נושאת באחריות למגבלות שהוטלו ו/או יוטלו ע"י הגורמים הרשמיים בדובאי, אשר עשויים למנוע כניסה לדובאי. לא יינתן החזר כספי בגין בקשת ויזה מכל סיבה שהיא, לרבות סירוב לבקשה וביטול טיסה.</li>
                            <li>לאחר השלמת הפרטים ושליחת הטופס אנו נחזור אליכם טלפונית לצורך גביית התשלום בגין הויזה.</li>
                            <li>אנו נשלח את האשרות בסמוך למועד הטיסה במייל או ב- whatsapp .</li>
                        </ul>

                    </div>

                </div>
            </div>
        )
    }
}

@inject ("formStore") @observer
class DubaiSection extends React.Component {
    render() {
        const s = this.props.formStore;

        if (!s.selectedDestination || (s.selectedDestination && s.selectedDestination.c !== 'DXB')) {
            return null;
        }

        const checkedBuyVisa = (s.needDubaiVisa === true);
        const checkedNoNeedVisa = (s.needDubaiVisa === false);

        return (
            <div>
                <div className={"row"}>
                    <div className={"col-md-12"}><br/>
                        <p>לקוחות יקרים,<br/> ביעד זה נדרשת אשרת כניסה למדינה (ויזה). הוצאת האשרה הינה באחריותכם.<br/>
                            יש להגיע עם האשרה מודפסת לנמל התעופה.</p>
                    </div>
                </div>

                    <div className={"row"}>
                        <div className={"form-group col-md-4"}>
                            <Form.Check name={"needVisa"} id={"order_need_visa"} type={"radio"} label={"אנו מעוניינים לרכוש ויזה"} checked={checkedBuyVisa} onChange={() => {
                                s.needDubaiVisa = true;
                            }}/>
                        </div>
                        <div className={"form-group col-md-4"}>
                            <Form.Check name={"needVisa"} id={"order_no_need_visa"} type={"radio"} label={"לא תודה, אנחנו מסודרים"} checked={checkedNoNeedVisa} onChange={() => {
                                s.needDubaiVisa = false;
                            }}/>
                        </div>
                    </div>

                <NoNeedVisa/>
                <VisaTypeSection/>


                </div>
        )
    }
}


export {DubaiSection}